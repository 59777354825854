<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <select-bus-box v-for="item in items" :key="item.id" :item='item' @onClick='onClick'/>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import SelectBusBox from '@/components/buses/selectBusBox.vue'
import { busesService } from '@/services/buses.service.js'
export default {
    components: {
        SelectBusBox,
        VueBreadcrumbs
    },
    data: () => ({
        filter: {
            adult: 1,
            child: 0,
            pensioner: 0,
            from_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dates: []
        },
        items: []
    }),
    computed: {
        breadCrumbs() {
            let firstQuery = {
                adult: this.$route.query.adult,
                child: this.$route.query.child,
                pensioner: this.$route.query.pensioner,
                from_date: this.$route.query.from_date,
                to_date: this.$route.query.to_date,
                from_location: this.$route.query.from_location,
                to_location: this.$route.query.to_location,
            }
            return [
                {
                    text: 'Бронирование автобусов',
                    to: `/booking/buses/`,
                    query: firstQuery
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/rt/${this.$route.params.id}/`,
                    query: this.$route.query
                },
                {
                    text: 'Обратно',
                    to: '/booking/tours',
                }
            ]
        }
    },
    created() {
        if (Object.keys(this.$route.query).length) {
            this.filter.adult = Number(this.$route.query.adult)
            this.filter.child = Number(this.$route.query.child)
            this.filter.pensioner = Number(this.$route.query.pensioner)
            this.filter.from_date = this.$route.query.to_date
            this.filter.from_location = Number(this.$route.query.to_location)
            this.filter.to_location = Number(this.$route.query.from_location)
            this.filter.hotel = Number(this.$route.query.hotel)
            this.filter.is_back = true
            this.filter.is_rt = true
            this.find()
        }
    },
    methods: {
        async find() {
            try {
                this.$loading(true)
                const { results } = await busesService.getActiveBuses(this.filter)
                this.items = results
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        onClick(item) {
            this.$router.push({
                path: `/booking/buses/rt/${this.$route.params.id}/search/${item.id}`,
                query: this.$route.query
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>